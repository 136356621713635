import { ArrowsOutSimple, X} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
// import { cn, templatesList } from "@rocket-resume/utils";

type Props = {
  url?: string;
  handleClosePreview?: () => void;
  selectedRId?: string | null;
};

export const ResumePreview = ({ url, handleClosePreview, selectedRId}: Props) => {

    const navigate = useNavigate();

    const onOpen = () => {
        navigate(`/builder/${selectedRId}`);
    };

    return(
    <div className="w-[87%] md:w-[68%] absolute md:relative xl:w-[42%] bg-[#3F3F3F] my-auto xl:mr-12 2xl:mr-20 py-2 xs:py-6 px-2 xs:px-10 md:px-6 xl:px-10">
        <div className="flex items-center justify-between">
            <ArrowsOutSimple size={28} className="cursor-pointer" onClick={onOpen}/>
            <X size={28} className="cursor-pointer" onClick={handleClosePreview}/>
        </div>
        <div className="mt-2 xs:mt-4">
            {/* <img src={`/templates/jpg/${templatesList[0]}.jpg`} alt={templatesList[0]} className="rounded-sm" />  */}
            {url && <img src={`${url}?cache=${Date.now()}`} className="rounded-sm" />}
        </div>
    </div>
)};
